import * as THREE from 'three'
import Experience from '../Experience.js'


export default class PunchPin
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.physics = this.experience.physics
        this.objectsToUpdate = this.experience.physics.objectsToUpdate
        this.debug = this.experience.debug

        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('Glass')
        }
        this.body = []
        // Resource
        this.resource = this.resources.items.punchPin

        this.setModel()
        
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
        //         child.castShadow = true
        //         // child.material = new THREE.MeshPhongMaterial({color: '#fff'})
        //         child.material.metalness= 0;
                child.material.roughness = 0;
                const material = new THREE.MeshPhysicalMaterial({
                    color: 0xffffff,
                    metalness: 1,
                    roughness: 0,
                    ior: 2.33,
                    thickness: 10,
                    transmission: 0,
                    side: THREE.DoubleSide,
                    clearcoat: 0.32,
                    reflectivity: 1,
                    envMapIntensity: 6,
                })
                child.material = material
                child.scale.set(0.03, 0.03, 0.03);
                this.body.push(this.physics.setPhysics(child, 'box'))
                
            }
        })
        this.scene.add(this.model)
        if(this.debug.active){
            
            this.debugFolder
                .add(this.model.children[0].material, 'metalness')
                .name('metalness')
                .min(0)
                .max(1)
                .step(0.01)
            this.debugFolder
                .add(this.model.children[0].material, 'roughness')
                .name('roughness')
                .min(0)
                .max(1)
                .step(0.01)
            this.debugFolder
                .add(this.model.children[0].material, 'thickness')
                .name('thickness')
                .min(0)
                .max(10)
                .step(0.01)
            this.debugFolder
                .add(this.model.children[0].material, 'transmission')
                .name('transmission')
                .min(0)
                .max(1)
                .step(0.01)
            this.debugFolder
                .add(this.model.children[0].material, 'clearcoat')
                .name('clearcoat')
                .min(0)
                .max(1)
                .step(0.01)
            this.debugFolder
                .add(this.model.children[0].material,'ior')
                .name('ior')
                .min(0)
                .max(4)
                .step(0.01)
            this.debugFolder
                .add(this.model.children[0].material,'reflectivity')
                .name('reflectivity')
                .min(0)
                .max(1)
                .step(0.01)
            this.debugFolder
                .add(this.model.children[0].material,'envMapIntensity')
                .name('envMapIntensity')
                .min(0)
                .max(10)
                .step(0.01)
        
        }
    }
}