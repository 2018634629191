/**
 * TYPE
 ** cubeTexture (environment setting)
 ** texture(texture)
 ** gltfModel
*/

const sources = [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        // path:
        // [
        //     'textures/environmentMap/px.jpg',
        //     'textures/environmentMap/nx.jpg',
        //     'textures/environmentMap/py.jpg',
        //     'textures/environmentMap/ny.jpg',
        //     'textures/environmentMap/pz.jpg',
        //     'textures/environmentMap/nz.jpg'
        // ]
        path:
        [
            'textures/environmentMap/studio-1/px.png',
            'textures/environmentMap/studio-1/nx.png',
            'textures/environmentMap/studio-1/py.png',
            'textures/environmentMap/studio-1/ny.png',
            'textures/environmentMap/studio-1/pz.png',
            'textures/environmentMap/studio-1/nz.png'
        ]
    },
    {
        name: 'glassNormalMap',
        type: 'texture',
        path: 'textures/normalMap/water_normal.jpeg'
    },
    {
        name: 'goldNormalMap',
        type: 'texture',
        path: 'textures/normalMap/scratched_gold.png'
    },
    {
        name: 'eraserPen',
        type: 'gltfModel',
        path: './models/stationery/glTF/EraserPen.gltf'
    },{
        name: 'paperClip',
        type: 'gltfModel',
        path: './models/stationery/glTF/PaperClip.gltf'
    },{
        name: 'punchPin',
        type: 'gltfModel',
        path: './models/stationery/glTF/PunchPin.gltf'
    },{
        name: 'rubberStamp',
        type: 'gltfModel',
        path: './models/stationery/glTF/RubberStamp.gltf'
    },{
        name: 'rubberBand',
        type: 'gltfModel',
        path: './models/stationery/glTF/RubberBand.gltf'
    },{
        name: 'sealTape',
        type: 'gltfModel',
        path: './models/stationery/glTF/SealTape.gltf'
    },{
        name: 'sharpner',
        type: 'gltfModel',
        path: './models/stationery/glTF/Sharpner.gltf'
    },{
        name: 'superGlueOpen',
        type: 'gltfModel',
        path: './models/stationery/glTF/SuperGlueOpen.gltf'
    },{
        name: 'thumbTack',
        type: 'gltfModel',
        path: './models/stationery/glTF/ThumbTack.gltf'
    },{
        name: 'BookRed',
        type: 'gltfModel',
        path: './models/stationery/glTF/BookRed.gltf'
    },{
        name: 'Scissors',
        type: 'gltfModel',
        path: './models/stationery/glTF/Scissors.gltf'
    },{
        name: 'OfficeLamp',
        type: 'gltfModel',
        path: './models/stationery/glTF/OfficeLamp.gltf'
    }
];

export default sources;