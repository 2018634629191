import * as THREE from 'three'
import Experience from '../Experience.js'


export default class BookRed
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.physics = this.experience.physics
        this.objectsToUpdate = this.experience.physics.objectsToUpdate
        this.body = []
        // Resource
        this.resource = this.resources.items.BookRed

        this.setModel()
        
    }

    setModel()
    {
        this.model = this.resource.scene
        // console.log(this.resource.scene)
        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.material.metalness= 0.5;
                child.material.roughness = 0;
                const material = new THREE.MeshPhysicalMaterial({
                    color: 0xffffff,
                    // metalness: 0.1,
                    // roughness: 0.05,
                    // ior: 1.9,
                    // thickness: 1,
                    // transmission: 1,
                    // side: THREE.DoubleSide,
                    // clearcoat: 0.5
                    metalness: 0.66,
                    roughness: 0.1,
                    ior: 1.33,
                    thickness: 10,
                    transmission: 0.8,
                    side: THREE.DoubleSide,
                    clearcoat: 1,
                    transparent: true,
                    opacity: 1,
                    reflectivity: 1,
                    envMapIntensity: 2,
                    attenuationColor: '#188989',
                    attenuationDistance: 0.1,
                    clearcoatNormalMap: this.resources.items.goldNormalMap,
                })
                child.material = material
                child.scale.set(0.05, 0.05, 0.05);
                this.body.push(this.physics.setPhysics(child, 'box'))
                
            }
        })
        this.scene.add(this.model)
    }
}