import * as THREE from 'three'
import Experience from '../Experience.js'


export default class SealTape
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.physics = this.experience.physics
        this.objectsToUpdate = this.experience.physics.objectsToUpdate
        this.body = []
        // Resource
        this.resource = this.resources.items.sealTape

        this.setModel()
        
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
                // child.material = new THREE.MeshPhongMaterial({color: '#fff'})
                child.material.metalness= 0.5;
                child.material.roughness = 0;
                const material = new THREE.MeshPhysicalMaterial({
                    color: 0xaaaaaa,
                    // metalness: 0.1,
                    // roughness: 0.05,
                    // ior: 1.9,
                    // thickness: 1,
                    // transmission: 1,
                    // side: THREE.DoubleSide,
                    // clearcoat: 0.5
                    metalness: 1,
                    roughness: 0.1,
                    ior: 1.92,
                    thickness: 5.5,
                    transmission: 0,
                    side: THREE.DoubleSide,
                    clearcoat: 0.45,
                    
                    // normalMap: this.resources.items.goldNormalMap,
                    // normalScale: new THREE.Vector2(0.1,0.1),
                    
                    reflectivity: 1,
                    envMapIntensity: 6,
                })
                child.material = material
                
                child.scale.set(0.018, 0.018, 0.018);
                this.body.push(this.physics.setPhysics(child, 'box'))
                
            }
        })
        this.scene.add(this.model)
    }
}