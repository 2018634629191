import * as THREE from 'three'
import Experience from '../Experience.js'


export default class Meshes
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.physics = this.experience.physics
        this.objectsToUpdate = this.experience.physics.objectsToUpdate

        this.setModel()
        
    }

    setModel()
    {
        const geometries = [
            new THREE.TetrahedronBufferGeometry(1, 0),
            new THREE.IcosahedronBufferGeometry(1, 0),
            new THREE.CapsuleGeometry(0.4,1,2,5),
            new THREE.TorusKnotBufferGeometry(0.4, 0.2, 56, 8, 2, 3)
        ]
        // const material = new THREE.MeshStandardMaterial({color:'#fff', metalness: 0.5})
        // const material = new THREE.MeshPhysicalMaterial({
        //     color: 0xffffff,
        //     // metalness: 0.1,
        //     // roughness: 0.05,
        //     // ior: 1.9,
        //     // thickness: 1,
        //     // transmission: 1,
        //     // side: THREE.DoubleSide,
        //     // clearcoat: 0.5
        //     metalness: 0.66,
        //     roughness: 0.3,
        //     ior: 4,
        //     thickness: 10,
        //     transmission: 0.92,
        //     side: THREE.DoubleSide,
        //     clearcoat: 0.32,
        //     // envMapIntensity: 0.9,
        //     transparent: true,
        //     opacity: 1,
        //     reflectivity: 0.2,
        // })
        const material = new THREE.MeshPhysicalMaterial({
            // color: 0x6fd7ea,
            color: 0xffffff,
            metalness: 0.5,
            roughness: 0.1,
            ior: 0.92,
            thickness: 0.05,
            transmission: 0.2,
            side: THREE.DoubleSide,
            clearcoat: 1,
            clearcoatRoughness: 0.22,
            transparent: true,
            opacity: 1,
            reflectivity: 0.3,
            attenuationColor: '#188989',
            attenuationDistance: 0.1,
            normalMap: this.resources.items.glassNormalMap,
            normalScale: new THREE.Vector2(0.1, 0.1),
            envMapIntensity: 4,
        })

        geometries.forEach(geo => {
            const mesh = new THREE.Mesh(geo, material)
            mesh.material.normalMap.wrapS = mesh.material.normalMap.wrapT = THREE.RepeatWrapping;
            this.physics.setPhysics(mesh)
            this.scene.add(mesh)
        });
    }
}