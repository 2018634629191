import * as THREE from 'three';
import Experience from '../Experience';

export default class Environment {
    constructor(){
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.debug = this.experience.debug;

        // Debug
        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('environment');
        }

        this.setDirectionalLight();
        this.setAmbientLight();
        this.setEnvironmentMap();
    }

    setDirectionalLight(){
        this.directionalLight = new THREE.DirectionalLight('#ffffff', 4);
        this.directionalLight.castShadow = true
        this.directionalLight.shadow.camera.far = 15
        this.directionalLight.shadow.mapSize.set(1024, 1024)
        this.directionalLight.shadow.normalBias = 0.05
        this.directionalLight.position.set(-5, 16, 13.5)
        this.scene.add(this.directionalLight)

        //Debug
        if(this.debug.active){
            this.debugFolder
                .add(this.directionalLight, 'intensity')
                .name('lightIntensity')
                .min(0)
                .max(10)
                .step(0.001)
            
                this.debugFolder.add(this.directionalLight.position, 'x')
                    .name('lightX')
                    .min(- 10)
                    .max(100)
                    .step(0.001)
                this.debugFolder.add(this.directionalLight.position, 'y')
                    .name('lightY')
                    .min(- 5)
                    .max(100)
                    .step(0.001)
                this.debugFolder.add(this.directionalLight.position, 'z')
                    .name('lightZ')
                    .min(- 5)
                    .max(100)
                    .step(0.001)
        }
    }

    setAmbientLight(){
        this.ambientLight = new THREE.AmbientLight('#aaa');
        this.scene.add(this.ambientLight);
    }

    setEnvironmentMap()
    {
        this.environmentMap = {}
        // this.environmentMap.intensity = 0.16
        this.environmentMap.intensity = 2
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.encoding = THREE.sRGBEncoding
        
        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () =>
        {
            this.scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .add(this.environmentMap, 'intensity')
                .name('envMapIntensity')
                .min(0)
                .max(4)
                .step(0.001)
                .onChange(this.environmentMap.updateMaterials)
        }
    }
}