import * as THREE from 'three'
import Experience from '../Experience.js'


export default class PaperClip
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.physics = this.experience.physics
        this.objectsToUpdate = this.experience.physics.objectsToUpdate
        this.body = []
        // Resource
        this.resource = this.resources.items.paperClip

        this.setModel()
        
    }

    setModel()
    {
        this.model = this.resource.scene
        // console.log(this.resource.scene)
        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.material.metalness= 0.5;
                child.material.roughness = 0;
                const material = new THREE.MeshPhysicalMaterial({
                    color: 0xffffff,
                    // metalness: 0.1,
                    // roughness: 0.05,
                    // ior: 1.9,
                    // thickness: 1,
                    // transmission: 1,
                    // side: THREE.DoubleSide,
                    // clearcoat: 0.5
                    metalness: 1,
                    roughness: 0,
                    ior: 4,
                    thickness: 10,
                    transmission: 0,
                    side: THREE.DoubleSide,
                    clearcoat: 0.32,
                    reflectivity: 1,
                    envMapIntensity: 2,
                })
                child.material = material
                child.scale.set(0.03, 0.03, 0.03);
                this.body.push(this.physics.setPhysics(child, 'box'))
                
            }
        })
        this.scene.add(this.model)
    }
}