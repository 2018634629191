import Experience from "../Experience";
import * as THREE from 'three'
import CANNON from 'cannon'

export default class Creators {
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.debug = this.experience.debug 
        this.physics = this.experience.physics
        this.objectsToUpdate = this.experience.physics.objectsToUpdate

        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('Creators');
        }

        this.spawn = {x:0, y:0.2, z:0}
        
        this.debugObject = {}

        this.addDebugger()
    }

    addDebugger(){
        this.debugObject.createSphere = () =>
        {
            this.createSphere(
                Math.random() * 0.5,
                {
                    x: (Math.random() - 0.5) * 3,
                    y: this.spawn.y,
                    z: (Math.random() - 0.5) * 3
                }
            )
        }
        if(this.debug.active) this.debugFolder.add(this.debugObject, 'createSphere');
        // gui.add(debugObject, 'createSphere')

        // debugObject.createBox = () =>
        // {
        //     createBox(
        //         Math.random() ,
        //         Math.random() ,
        //         Math.random() ,
        //         {
        //             x: (Math.random() - 0.5) * 3 ,
        //             y: 2,
        //             z: (Math.random() - 0.5) * 3
        //         }
        //     )
        // }
        // gui.add(debugObject, 'createBox')

        // // Reset
        // debugObject.reset = () =>
        // {
        //     for(const object of objectsToUpdate)
        //     {
        //         // Remove body
        //         // object.body.removeEventListener('collide', playHitSound)
        //         world.removeBody(object.body)

        //         // Remove mesh
        //         scene.remove(object.mesh)
        //     }
            
        //     objectsToUpdate.splice(0, objectsToUpdate.length)
        // }
        // gui.add(debugObject, 'reset')
    }

    createSphere = (radius, position) =>{
        
        //WORLD
        const geometry = new THREE.SphereGeometry(1,20,20);
        const material = new THREE.MeshStandardMaterial({
            color: "#fff"
        })
        const mesh = new THREE.Mesh(geometry, material);
        // mesh.material.visible
        mesh.castShadow = true
        mesh.scale.set(radius, radius, radius)
        mesh.position.copy(position)
        this.scene.add(mesh);

        //CANNON
        const shape = new CANNON.Sphere(radius)
        const body = new CANNON.Body({
            mass:1,
            position: new CANNON.Vec3(0, this.spawn.y, 0),
            shape: shape,
            material: this.physics.defaultMaterial
        })
        body.position.copy(position)
        // body.linearDamping = 10;
        this.physics.world.addBody(body)

        this.objectsToUpdate.push({mesh, body})
        // console.log(this.objectsToUpdate);
    }

    createBox = (width, height, depth, position) =>{

    }

    update(){
        // this.objectsToUpdate.forEach(object=>{
        //     object.mesh.position.copy(object.body.position)
        //     object.mesh.quaternion.copy(object.body.quaternion)
        // })
    }

    
}