import Experience from "./Experience"
import CANNON from "cannon"
import CannonDebugger from "cannon-es-debugger"
import Time from "./Utils/Time"
import { ShapeType, threeToCannon } from 'three-to-cannon';

export default class Physics{
    constructor(){
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.vars = this.experience.vars
        this.active = false;
        this.time = new Time()
        this.objectsToUpdate = []
        this.mouse = {x: this.experience.sizes.width/2, y:this.experience.sizes.height/2}
        this.scales = 1.15;
        
        this.mouseTimer = this.setTimer();

        this.setWorld()
        this.setWorldMaterial()

        this.setDebugger()
        this.moveGravity()
    }

    setWorld(){
        this.world = new CANNON.World()
        this.world.broadphase = new CANNON.SAPBroadphase(this.world);
        // this.world.allowSleep = true;
        this.world.gravity.set(0, 0, 0);
        this.world.solver.delta = 0
        // console.log(this.world.solver)
    }

    setWorldMaterial(){
        this.defaultMaterial = new CANNON.Material('default');
        this.defaultContactMaterial = new CANNON.ContactMaterial(
            this.defaultMaterial,
            this.defaultMaterial,{
                friction: 0.1,
                restitution: 0.8
            }
        );

        this.world.defaultContactMaterial = this.defaultContactMaterial;
    }

    setPhysics(child, type){
        // let geoClone = child.geometry.clone();
        // geoClone = this.reduceVerticies(geoClone);
        const res = child;
        // res.geometry = geoClone;
        let t;
        switch(type){
            case null :
                t = {type: ShapeType.HULL}
                break;
            case 'box':
                t = {type: ShapeType.BOX}
                break;
        }

        const result = threeToCannon(res, t)

        const {shape, offset, quaternion} = result;
        
        const body = new CANNON.Body({
            mass:1,
            position: new CANNON.Vec3(0, 0, 0),
            material: this.defaultMaterial
        })
        body.addShape(shape, offset, quaternion);
        body.position.copy(child.position)
        this.world.addBody(body)

        this.objectsToUpdate.push({mesh: child, body})
        return {
            body,
            'extents' : {
                base: shape.halfExtents,
                scale: {
                    x: shape.halfExtents.x* 1.15,
                    y: shape.halfExtents.y* 1.15,
                    z: shape.halfExtents.z* 1.15
                }
            }
        }
    }

    setDebugger(){
        this.cannonDebugger = CannonDebugger(this.scene, this.world);
    }

    setTimer = () => {
        const S = {
            timer : null,
            limit: 1000 * 5,
            fnc: ()=>{
                this.world.gravity.set(0, 0, 0);
                this.objectsToUpdate.forEach(el => {
                    const vector = el.mesh.position;
                    vector.project(this.experience.camera.instance);

                    vector.x = Math.round((0.5 + vector.x / 2) * (this.canvas.width / window.devicePixelRatio));
                    vector.y = Math.round((0.5 - vector.y / 2) * (this.canvas.height / window.devicePixelRatio));
                    this.setImpulse(el.body,vector.x, vector.y)
                })
                S.reset()
            },
            start: ()=>{
                S.timer = window.setTimeout(S.fnc, S.limit);
            },
            reset: ()=>{
                window.clearTimeout(S.timer)
                S.start();
            }
        }
        document.onmousemove = () => {S.reset()}
        return S;
    }

    setImpulse = (body, x, y)=> {
        const mousex = x / window.innerWidth * 20 - 10;
        const mousey = y / window.innerHeight * 20 - 10;
        const velocity = body.velocity;
        velocity.x += mousex;
        velocity.y += mousey;
    }

    setGravity = (x, y) => {
        //event: {x.y}
        const mousex = x / window.innerWidth * 20 - 10;
        const mousey = y / window.innerHeight * 20 - 10;
        
        this.world.gravity.set(mousex /2, -mousey/2, 0);
    }

    moveGravity(){
        this.mouseTimer.start();
        window.addEventListener("click", (e)=>{
            this.objectsToUpdate.forEach(el => {
                this.setImpulse(el.body, e.x, e.y)
            })
        })

        this.canvas.addEventListener("mousemove", (e)=> {
            this.mouse.x = e.x;
            this.mouse.y = e.y;
            this.setGravity(e.x, e.y)
        })

        this.canvas.addEventListener("touchstart", (e)=>{
            this.setGravity(e.touches[0].screenX, e.touches[0].screenY)
        },false)
        this.canvas.addEventListener("touchmove", (e)=>{

        },false)
        this.canvas.addEventListener("touchend", (e)=>{

        },false)
    }


    update(){
        // this.world.step(1 / 60, this.time.delta, 3)
        this.world.step(1 / 120, this.time.delta, 10)
        if(this.active){
            this.cannonDebugger.update()
        }
        for(const object of this.objectsToUpdate)
        {
            object.mesh.position.copy(object.body.position)
            object.mesh.quaternion.copy(object.body.quaternion)
        }
    }
}