import Experience from '../Experience.js'
import BookRed from './BookRed.js'
import Creators from './Creators.js'
import Environment from './Environment.js'
import EraserPen from './EraserPen.js'
import Meshes from './Meshes.js'
import PaperClip from './PaperClip.js'
import PunchPin from './PunchPin.js'
import RubberBand from './RubberBand.js'
import RubberStamp from './RubberStamp.js'
import SealTape from './SealTape.js'
import Sharpner from './Sharpner.js'
import SuperGlueOpen from './SuperGlueOpen.js'
import ThumbTack from './ThumbTack.js'
import OfficeLamp from './OfficeLamp.js'
import Scissors from './Scissors.js'
import Warehouse from './Warehouse.js'
import Spheres from './Spheres.js'

export default class World{
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.physics = this.experience.physics
        this.sizes = this.experience.sizes
        
        // this.warehouse = new Warehouse();
        this.creators = new Creators();
        this.objects = [];
        // this.environment = new Environment()

        // Wait for resources
        this.warehouse = new Warehouse();
        this.resources.on('ready', () =>
        {
            // Setup
            this.environment = new Environment();
            this.spheres = new Spheres();
            this.meshes = new Meshes()
            this.objects.push(new EraserPen());
            this.objects.push(new PaperClip());
            this.objects.push(new PunchPin());
            this.objects.push(new RubberStamp());
            this.objects.push(new RubberBand());
            this.objects.push(new SealTape());
            this.objects.push(new Sharpner());
            this.objects.push(new SuperGlueOpen());
            this.objects.push(new ThumbTack());

            if(this.sizes.width > 960){
                const randObject_arr = ['book', 'lamp', 'scissors'];
                const randObject = randObject_arr[Math.floor(Math.random()*randObject_arr.length)];
                
                switch (randObject){
                    case 'book':
                        this.objects.push(new BookRed());
                        break;
                    case 'lamp':
                        this.objects.push(new OfficeLamp());
                        break;
                    case 'scissors':
                       this.objects.push(new Scissors());
                        break;
                }
            }

            // this.objects.push(new BookRed());
            // this.objects.push(new OfficeLamp());
            // this.objects.push(new Scissors());

            this.scaleObjs();
        })

        window.addEventListener('resize', ()=>{
            this.scaleObjs()
        })
    }

    scaleObjs(){
        let scale = (this.sizes.width > 1280) ? 1.15 : 1;
        if(this.sizes.width >1600){
            scale = 1.3;
        }
        if(this.sizes.width >1800){
            scale = 1.4;
        }

        this.objects.forEach(obj=>{
            obj.model.scale.set(scale, scale, scale)
            obj.body.forEach(el => {
                el.body.shapes.forEach(shape =>{
                    if(this.sizes.width > 1280){

                        shape.halfExtents.x = el.extents.scale.x
                        shape.halfExtents.y = el.extents.scale.y
                        shape.halfExtents.z = el.extents.scale.z

                    }else{
                        shape.halfExtents.x = el.extents.base.x
                        shape.halfExtents.y = el.extents.base.y
                        shape.halfExtents.z = el.extents.base.z
                    }
                    shape.boundingSphereRadiusNeedsUpdate = true;
                    shape.updateConvexPolyhedronRepresentation()
                })
            })
        })
    }

    update()
    {
        if(this.creators) this.creators.update()
        // if(this.warehouse) this.warehouse.update()
        // if(this.physics){
        //     this.physics
        // }
        // if(this.fox)
            // this.fox.update()
    }
}