import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.vars = this.experience.vars
        this.debug = this.experience.debug

        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('Camera')
        }

        this.setInstance()
        // this.setControls()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(this.vars.fov, this.sizes.width / this.sizes.height, 0.1, 10000);
        this.instance.position.set(0,0,this.vars.cameraZ + this.vars.fov)
        this.scene.add(this.instance)

        if(this.debug.active){
            this.debugFolder
                .add(this.instance.position, 'x')
                .name('cameraPositionX')
                .min(0)
                .max(300)
                .step(0.001)
            this.debugFolder
                .add(this.instance.position, 'y')
                .name('cameraPositiony')
                .min(0)
                .max(300)
                .step(0.001)
            this.debugFolder
                .add(this.instance.position, 'z')
                .name('cameraPositionz')
                .min(0)
                .max(300)
                .step(0.001)
            this.debugFolder
                .add(this.instance, 'zoom')
                .name('cameraZoom')
                .min(0)
                .max(300)
                .step(0.001)
        }
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        if(this.controls) this.controls.update()
    }
}