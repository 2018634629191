import * as THREE from 'three'
import Experience from '../Experience'
import CANNON from 'cannon'

export default class Warehouse {
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.vars = this.experience.vars
        this.physics = this.experience.physics

        this.group = new THREE.Group()
        this.physicsGroup =[]
        //three
        // this.makeWalls()
        this.setWarehouse()
        //cannon
        this.setPhysics()
        this.resize();
    }

    makePlane(w, h){
        const geometry = new THREE.PlaneBufferGeometry(1, 1);
        const material = new THREE.MeshToonMaterial({
            color: '#fff',
            side: THREE.DoubleSide
        })
        const mesh = new THREE.Mesh(geometry, material)
        mesh.scale.set(w,h)
        return mesh;
    }
    
    makeWalls(){
        const ware_w = (this.vars.h * this.vars.halfScale +  this.vars.halfScale) * ( this.sizes.width/ this.sizes.height);
        const floor_1 = this.makePlane(-ware_w / (this.vars.halfScale * 2), this.vars.d);
        const floor_2 = this.makePlane(-ware_w / (this.vars.halfScale * 2), this.vars.d);
        const wall_1 = this.makePlane(this.vars.d, this.vars.h);
        const wall_2 = this.makePlane(this.vars.d, this.vars.h);
        const wall_3 = this.makePlane(-ware_w / (this.vars.halfScale * 2), this.vars.h);

        floor_1.rotation.x = -Math.PI * 0.5;
        floor_1.receiveShadow = true;
        floor_1.name ="floor1"
        
        floor_2.rotation.x = -Math.PI * 0.5;
        floor_2.position.set(0, this.vars.h, 0);
        floor_2.name ="floor2"

        wall_1.rotation.y = -Math.PI * 0.5;
        wall_1.position.set(-ware_w / (this.vars.halfScale * 4), this.vars.h/2, 0);
        wall_1.name ="wall1"

        wall_2.rotation.y = -Math.PI * 0.5;
        wall_2.position.set(ware_w / (this.vars.halfScale * 4), this.vars.h/2, 0);
        wall_2.name ="wall2"

        wall_3.position.set(0, this.vars.h/2, this.vars.d/-2);
        wall_3.name ="wall3"

        this.group.add(floor_1)
        this.group.add(floor_2)
        this.group.add(wall_1)
        this.group.add(wall_2)
        this.group.add(wall_3)
    }

    setWarehouse(){
        this.group.scale.set(this.vars.halfScale*2, this.vars.halfScale*2, this.vars.halfScale*2);
        this.group.position.y = -this.vars.halfScale
        this.scene.add(this.group)
    }

    makePhysicsPlane(position, axis, angle){
        const body = new CANNON.Body()
        body.mass = 0;
        body.addShape(new CANNON.Plane())
        body.position.copy(position)
        body.quaternion.setFromAxisAngle(axis, angle)
        
        this.physics.world.addBody(body);
        this.physicsGroup.push(body)
    }

    setPhysics(){
        const ware_w = (this.vars.h * this.vars.halfScale +  this.vars.halfScale) * ( this.sizes.width/ this.sizes.height);
        this.makePhysicsPlane(this.group.position, new CANNON.Vec3(-1, 0, 0), Math.PI * 0.5);
        this.makePhysicsPlane(new CANNON.Vec3(0, this.vars.h * this.vars.halfScale, 0), new CANNON.Vec3(1, 0, 0), Math.PI * 0.5);
        this.makePhysicsPlane(new CANNON.Vec3(-ware_w / 2, 0, 0), new CANNON.Vec3(0, 1, 0), Math.PI * 0.5)
        this.makePhysicsPlane(new CANNON.Vec3(ware_w / 2, 0, 0), new CANNON.Vec3(0, -1, 0), Math.PI * 0.5)
        this.makePhysicsPlane(new CANNON.Vec3(0, 0, (this.vars.d/-2) * (this.vars.halfScale * 2)), new CANNON.Vec3(0, 0, 0), Math.PI * 0.5)
        this.makePhysicsPlane(new CANNON.Vec3(0, 0, -(this.vars.d/-2) * (this.vars.halfScale * 2)), new CANNON.Vec3(0, 1, 0), Math.PI * 1)
        
    }

    resize(){
        window.addEventListener('resize', ()=>{
            const ware_w = (this.vars.h * this.vars.halfScale +  this.vars.halfScale) * ( this.sizes.width/ this.sizes.height);
            this.group.children.forEach((el)=>{
                if(el.name === 'wall1'){
                    el.position.set(-ware_w / (this.vars.halfScale * 4), this.vars.h/2, 0);
                }else if(el.name === 'wall2'){
                    el.position.set(ware_w / (this.vars.halfScale * 4), this.vars.h/2, 0);
                }else{
                    el.scale.set(Math.abs(-ware_w / (this.vars.halfScale * 2)), el.scale.y, el.scale.z);
                    el.updateMatrix();
                }
            });
            this.physicsGroup.forEach((el,idx)=>{
                if(idx === 2){
                    el.position.x=-ware_w / 2;
                }else if(idx === 3){
                    el.position.x=ware_w / 2;
                    el.position.copy(new CANNON.Vec3(ware_w / 2, 0, 0))
                }
            })
        })
    }
}