import Experience from '../Experience.js';
import EventEmitter from './EventEmitter.js'

export default class Vars{
    constructor()
    {
        this.sizes = new Experience().sizes
        
        // Setup
        this.w = 1;
        this.d = 1.5;
        this.h = 1;
        this.fov = 10;
        this.cameraZ = 35;
        window.addEventListener("resize",()=>{
            if(this.sizes.width<980){
                this.cameraZ = 10
            }
        })
        this.halfScale = this.cameraZ/this.fov
        this.wd = (this.h * this.halfScale +  this.halfScale) * ( this.sizes.width/ this.sizes.height);
    }
}